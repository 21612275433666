import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private loggedIn: boolean; 
  title = 'Lexeed';

  constructor(private auth: AuthService){
    this.checkLogin();
  }

  checkLogin() {
    this.loggedIn = this.auth.isLoggedIn();
  }

  logout() {
    this.auth.logout();
    this.checkLogin();
  }
}
