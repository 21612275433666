import { Component, OnInit, ViewChild, Input, EventEmitter } from '@angular/core';
import { ExamineeService } from 'src/app/services/examinee.service';
import { Examinee } from 'src/app/interfaces/examinee';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { BooleanDialogComponent } from '../boolean-dialog/boolean-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { asyncScheduler } from 'rxjs';

@Component({
  selector: 'app-examinees',
  templateUrl: './examinees.component.html',
  styleUrls: ['./examinees.component.scss']
})

export class ExamineesComponent implements OnInit {
  @Input() examinee: Examinee;
  dataSource;
  examinees: Examinee[] = [];
  loggedIn: boolean = false;

  constructor (private examineeService: ExamineeService,public dialog: MatDialog, private authService: AuthService) {
    this.loginTest();
    this.getExaminees();
  }
  displayedColumns: string[] = ['firstName', 'lastName', 'id', 'email','actions'];


  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  ngOnInit() {
    //this.dataSource.paginator = this.paginator;
  }
  
  async getExaminees() {
    let examinees = await this.examineeService.getExaminees();
    console.log(examinees);
    this.dataSource = new MatTableDataSource<Examinee>(examinees);
  }

  async loginTest(){
    
    let test = await this.authService.test();
    this.loggedIn = test != null;
  }

  examPDF(id){
    window.open("https://lexeed.interaction-room.de/api/examinee/" + id + "/exam", "_blank");
    //this.examineeService.getExamPDF(this.examinee.id);
  }
  examAnswersPDF(id){
    window.open("https://lexeed.interaction-room.de/api/examinee/" + id + "/examAnswers", "_blank");
    //this.examineeService.getExamAnswersPDF(this.examinee.id);
  }

  passedExam(id): void {
    let obj = {
      data:{ 
        text: "Wollen Sie die Prüfung wirklich als bestanden markieren?", 
        acceptText: "OK", 
        cancelText: "Abbrechen", 
        acceptEvent: new EventEmitter(), 
        cancelEvent: new EventEmitter(),
      }
    };
    obj.data.acceptEvent.subscribe(() => {
      this.examineeService.setPassedExam(id);
    });
    const examineePassedDialogRef = this.dialog.open(BooleanDialogComponent, obj);
    examineePassedDialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  regenerateExam(id){
    let obj = {
      data:{ 
        text: "Wollen sie wirklich eine neue Prüfung generieren?", 
        acceptText: "OK", 
        cancelText: "Abbrechen", 
        acceptEvent: new EventEmitter(), 
        cancelEvent: new EventEmitter(),
      }
    };
    obj.data.acceptEvent.subscribe(() => {
      this.examineeService.newExam(id);
    });
    const regenerateExamDialogRef = this.dialog.open(BooleanDialogComponent, obj);
    regenerateExamDialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
