import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  private email: string;
  private password: string;
  @Output() justLoggedIn: any = new EventEmitter();

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  async login(){
    let res = await this.authService.login(this.email, this.password);
    if(res) this.justLoggedIn.emit(); 
  }
}
