import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-boolean-dialog',
  templateUrl: './boolean-dialog.component.html',
  styleUrls: ['./boolean-dialog.component.scss']
})
export class BooleanDialogComponent {

  constructor(public dialogRef:MatDialogRef<BooleanDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 

  }
  
  accept() {
    //console.log(this.data);
    this.data.acceptEvent.emit();   
    this.dialogRef.close();
  }

  cancel(){
    this.data.cancelEvent.emit();
    this.dialogRef.close()
  }

}
