import { Component, OnInit } from '@angular/core';
import { Examinee } from 'src/app/interfaces/examinee';
import { ExamineeService } from 'src/app/services/examinee.service';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-new-examinee',
  templateUrl: './new-examinee.component.html',
  styleUrls: ['./new-examinee.component.scss']
})
export class NewExamineeComponent implements OnInit {

  newExaminee: Examinee = {} as Examinee;
  firstNameFormControl = new FormControl('', [
    Validators.required
  ]);
  lastNameFormControl = new FormControl('', [
    Validators.required
  ]);
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  matcher = new MyErrorStateMatcher();

  constructor(private examineeService: ExamineeService, private router: Router) { }

  ngOnInit() {
  }

  async createExaminee(){
    if(this.firstNameFormControl.errors == null && this.lastNameFormControl.errors == null && this.emailFormControl.errors == null){
      this.newExaminee.firstName = this.firstNameFormControl.value;
      this.newExaminee.lastName = this.lastNameFormControl.value;
      this.newExaminee.email = this.emailFormControl.value;
      let res = await this.examineeService.newExaminee(this.newExaminee);
      if(res) this.router.navigate(['/']);
    } 
  }

}
