import { Component, OnInit, Input } from '@angular/core';
import { Examinee } from 'src/app/interfaces/examinee';
import { ExamineeService } from 'src/app/services/examinee.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-examinee-card',
  templateUrl: './examinee-card.component.html',
  styleUrls: ['./examinee-card.component.scss']
})
export class ExamineeCardComponent implements OnInit {
  @Input() examinee: Examinee;

  constructor(private examineeService: ExamineeService,public dialog: MatDialog) { }

  ngOnInit() {
  }

  bestanden(){
    this.examineeService.setPassedExam(this.examinee.id);
  }
  examPDF(){
    window.open("https://lexeed.interaction-room.de/api/examinee/" + this.examinee.id + "/exam", "_blank");
    //this.examineeService.getExamPDF(this.examinee.id);
  }
  examAnswersPDF(){
    window.open("https://lexeed.interaction-room.de/api/examinee/" + this.examinee.id + "/examAnswers", "_blank");
    //this.examineeService.getExamAnswersPDF(this.examinee.id);
  }

  generateNewExam(){
    this.examineeService.newExam(this.examinee.id);
  }


}
