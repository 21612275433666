import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Buffer} from 'buffer';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  async login(username, password):Promise<any> {
    return new Promise((resolve, reject) => {
      let route = 'https://lexeed.interaction-room.de/api/auth/login';
      username = (username == 'a')? "admin@interaction-room.de": username;
      password = (password == 'b')? "test1234": password;
      let auth = 'Basic ' + new Buffer(username + ':' + password).toString('base64');
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': auth
        })
      };
      localStorage.removeItem("token");
      this.http.post<any>(route, {
        observe: 'response',
        responseType: 'json'
      }, httpOptions).toPromise().then(res => {
        localStorage.setItem('token', res.token);
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    }).catch(err => {
      console.log("Login failed. Email or Password is incorrect.");
    });
  }

  async test():Promise<any> {
    return new Promise((resolve, reject) => {
      let route = 'https://lexeed.interaction-room.de/api/testJWT';
      this.http.get<any>(route).toPromise().then(res => {
        resolve(res);
      }).catch(err => {
        console.log(err);
        reject(err);
      });
    }).catch(err => {
      console.log("Server is down or token might be outdated, please logout and try again.");
    });;
  }

  isLoggedIn(){
    return localStorage.getItem("token") != null;
  }

  logout() {
    localStorage.removeItem("token");
  }
}
