import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Examinee } from './../interfaces/examinee';

@Injectable()
export class ExamineeService {

  constructor(private http: HttpClient) {
   }

 async getExaminees():Promise<Examinee[]> {
    return new Promise((resolve, reject) => {
      let route = 'https://lexeed.interaction-room.de/api/examinee';
    
      this.http.get<any>(route, {
        observe: 'response',
        responseType: 'json'
      }).toPromise().then(res => {
        resolve(res.body);
      }).catch(err => {
        console.log(err);
        resolve([]);
      });
    });
  }

  async newExaminee(examinee: Examinee){
    return new Promise((resolve, reject) => {
      let route = 'https://lexeed.interaction-room.de/api/examinee?firstName='+examinee.firstName+'&lastName='+examinee.lastName+'&email='+examinee.email+'';
    
      this.http.post<any>(route, {
        observe: 'response',
        responseType: 'json'
      }).toPromise().then(res => {
        resolve(res.body);
      }).catch(err => {
        console.log(err);
        resolve(null);
      });
    });
  } 


  async newExam(id:String){
    return new Promise((resolve, reject) => {
      let route = 'https://lexeed.interaction-room.de/api/examinee/'+id+'/newExam';
    
      this.http.post<any>(route, {
        observe: 'response',
        responseType: 'json'
      }).toPromise().then(res => {
        resolve(res.body);
      }).catch(err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  async setPassedExam(id: String){
    return new Promise((resolve, reject) => {
      let route = 'https://lexeed.interaction-room.de/api/examinee/'+id+'/passed';
    
      this.http.put<any>(route, {
        observe: 'response',
        responseType: 'json'
      }).toPromise().then(res => {
        resolve(res.body);
      }).catch(err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  async getExamPDF(id: String){
    let route = 'https://lexeed.interaction-room.de/api/examinee/:id/exam';
    window.open("https://www.google.com", "_blank");
  }

  async getExamAnswersPDF(id: String){
    let route = 'https://lexeed.interaction-room.de/api/examinee/:id/examAnswers';
    window.open("https://www.youtube.com", "_blank");
  }
}
